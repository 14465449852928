<template>
  <CRow class="languages-summary">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">Translations</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--        TABS-->
            <CRow class="mb-4">
              <CCol col="12" class="d-flex flex-wrap align-items-center">
                <ul class="translation-tabs">
                  <li v-for="(lang, index) in languages" :key="index" class="translation-tab-item">
                    <button
                        class="translation-tab-button"
                        :class="{'translation-tab-button__active': currentLang !== lang}"
                        @click="handleTranslation(lang)">
                       <span
                           v-if="langData[lang].subject && (!settingsData.description || langData[lang].description)"
                           :class="{'check-icon__active': currentLang !== lang}"
                           class='exclaim check-icon'
                       >
                        <i class="fa fa-check"></i>
                      </span>

                      <span
                          v-else
                          :class="{'exclaim__active': currentLang !== lang}"
                          class="exclaim">
                        <i class="fa fa-exclamation"></i>
                      </span>

                      <span :class="{'translation-tab-lang__active': currentLang !== lang}">{{ lang }}</span>
                    </button>
                  </li>
                </ul>
              </CCol>
            </CRow>
            <!--     Fields      -->
            <div v-if="currentLang">
              <CRow class="mb-3">
                <CCol col="12" md="5" class="text-left">
                  <CInput
                      v-model="settingsData.subject"
                      add-input-classes="col-sm-12"
                      disabled="true"
                  />
                </CCol>
                <CCol class="d-flex flex-column align-items-center text-center justify-content-center mb-2">
                  <CIcon name="cil-arrow-right" class='check-icon' />
                  <span>  to {{ currentLang }}</span>
                </CCol>
                <CCol col="12" md="5">
                  <CInput
                      :value="langData[currentLang].subject || ''"
                      add-input-classes="col-sm-12"
                      placeholder=""
                      :disabled="true"
                  />
                </CCol>
              </CRow>
              <!--   Body     -->
              <h6 class="mb-2">Body</h6>
              <CRow class="mb-4">
                <CCol col="12" md="5">
                  <ClEditor
                      :value-prop="settingsData.body"
                      :disabled="true"
                  />
                </CCol>
                <CCol class="d-flex flex-column align-items-center text-center justify-content-center mb-2">
                  <CIcon name="cil-arrow-right" class='check-icon' />
                  <span>  to {{ currentLang }}</span>
                </CCol>

                <CCol col="12" md="5">
                  <ClEditor
                      :value-prop="langData[currentLang].body || ''"
                      :disabled="true"
                  />
                </CCol>
              </CRow>

            </div>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {getLanguagesData} from "@/utils/translationsUtils";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import ClEditor from "@/shared/components/formComponents/ClEditor";

export default {
  components: {
    ClCardArrow,
    ClEditor
  },
  props: {
    translationsData: Object,
    settingsData: Object,
  },
  data() {
    return {
      editorOptions: {
        placeholder: '',
      },
      basicIsCollapsed: false,
      currentLang: "",
      languages: ['German', 'Spanish', 'Italian', 'Russian'],
      langData: {},
      isToolbar: false
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const isFormData = Object.keys(this.translationsData).length;
      this.langData = isFormData ? this.translationsData : getLanguagesData(this.languages);
      //console.log(this.langData['German']);
    },
    handleTranslation(lang) {
      this.currentLang = lang;
    },
  }
}
</script>

<style lang="scss">


.languages-summary {
  .ql-snow {
    background: #d8dbe0;
    color: #768192;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .ql-toolbar {
    background: white;

  }


}
</style>